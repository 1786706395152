export enum NodeEnv {
  Development = 'development',
  Production = 'production',
}

export enum FileKind {
  Image = 1,
  Video = 2,
  Document = 3,
}

export enum HttpStatus {
  Success = 200,
  Failure = 400,
  InternalServerError = 500,
  NotFound = 404,
  Unauthorized = 401,
  BadGateway = 503,
}

export enum StorageName {
  Site = 'BUDGET_Site',
  Language = 'BUDGET_Lang',
}

export enum LocalStorageItems {
  iamCode = 'iam-code',
  iamState = 'iam-state',
  access_token = 'access_token',
  profile_info = 'profile_info',
  lastPageUrl = 'last_page_url',
  permissions = 'user_permissions',
  currentUser = 'current_user',
}

export enum PaginationStrings {
  pageSize = 10,
}

export enum DemandStatusEnum {
  Draft = 1,
  WaitingApprove = 2,
  Approved = 3,
  Rejected = 4,
  RejectedByBuyer = 5,
  ConvertedToOffer = 6,
  NeedRevision = 7,
}

export enum OfferStatusEnum {
  WaitingApprove = 1,
  Approved = 2,
  Rejected = 3,
  NeedRevision = 4,
  PurchasingConfirmed = 5,
  PurchasingRejected = 6,
}

export enum ProjectBudgetTypeEnum {
  General = 1,
  Others = 2,
}

export enum PaymentTypeEnum {
  Advance = 1,
  CreditCard = 2,
  Futures = 3,
}

export enum FuturesTypeEnum {
  Deferred = 1,
  Installment = 2,
}

export enum ProjectStatusEnum {
  Active = 1,
  Completed = 2,
  Cancelled = 3,
}

export enum PurchaseTypeEnum {
  Only = 1,
  Periodic = 2,
}

export enum BillTypeEnum {
  Invoice = 1,
  Archive = 2,
}

export enum DemandProcessTypeEnum {
  DemandWaitApproval = 1,
  DemandWaitPurchase = 2,
  OfferWaitApproval = 3,
  OfferRevision = 4,
  OfferWaitPurchase = 5,
  ProcessCompleted = 6,
}
